<template>
  <fib-component-bus-outlet
    data-test-id="mantle-shared-modals"
    :components="modals"
    :activate-delay="200"
  >
    <template #default="{ id, name, props, active }">
      <component
        :is="registeredModals[name]"
        :active="active"
        v-bind="props"
        @close="closeModal(id, name, $event)"
      />
    </template>
  </fib-component-bus-outlet>
</template>

<script setup lang="ts">
  import { FibComponentBusOutlet } from '@feeditback/fib-components';
  import { defineAsyncComponent } from 'vue';

  import { useSharedModals } from '../../use/useSharedModals';

  const registeredModals = {
    MantleConfigurationsDetailModal: defineAsyncComponent(
      () => import('./MantleConfigurationsDetailModal.vue')
    ),
    MantleTopicSetsDetailModal: defineAsyncComponent(
      () => import('./MantleTopicSetsDetailModal.vue')
    ),
    MantleTopicsDetailModal: defineAsyncComponent(() => import('./MantleTopicsDetailModal.vue')),
    MantleUsersDetailModal: defineAsyncComponent(() => import('./MantleUsersDetailModal.vue')),
    MantleTaxonomiesDetailModal: defineAsyncComponent(
      () => import('./MantleTaxonomiesDetailModal.vue')
    ),
    MantleSentimentsDetailModal: defineAsyncComponent(
      () => import('./MantleSentimentsDetailModal.vue')
    )
  } as const;

  // ** Data **
  const { modals, closeModal } = useSharedModals();
</script>
