import { piniaPluginPagination, useFibComponents } from '@feeditback/fib-components';
import * as Sentry from '@sentry/vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createApp } from 'vue';

import '../node_modules/@feeditback/fib-components/dist/style.css';
import '../node_modules/@feeditback/fib-components/dist/assets/styles/scss/index.scss';

import App from './App.vue';
import { useRouter } from './router';
import './chartjs';
import './assets/styles/scss/mantle.scss';

// Top level awaits aren't available yet, so we have to do this the old-fashioned way :(
(async (): Promise<void> => {
  const { i18n } = await useFibComponents({ files: import.meta.glob('./locales/**/*.json') });

  const router = useRouter();

  const app = createApp(App)
    .use(i18n)
    .use(router)
    .use(createPinia().use(piniaPluginPersistedstate).use(piniaPluginPagination));

  if (import.meta.env.VITE_SENTRY_LOGGING === 'TRUE') {
    Sentry.init({
      app,
      environment: import.meta.env.VITE_ENVIRONMENT,
      dsn: 'https://cf5e3e6e7ff2d9a5684b5a8df8c78ec8@o4507905269432320.ingest.de.sentry.io/4508041211543632',
      integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
      // Tracing
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost'],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }

  await router.isReady();

  app.mount('#app');
})();
