import { t, useMessageBox } from '@feeditback/fib-components';
import * as Sentry from '@sentry/vue';
import type { AxiosError, AxiosInstance, AxiosRequestConfig, Method } from 'axios';
import type { AxiosResponse } from 'axios';
import { isAxiosError } from 'axios';

/** Result wrapper for api responses. 'errored' acts as a type discriminator here. */
export type ApiResult<Response> = { response?: AxiosResponse } & (
  | { errored: true; data: undefined; error: { message: string | undefined } }
  | { errored: false; data: Response }
);

export type ApiPromise<Response> = Promise<ApiResult<Response>>;

declare module 'axios' {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  export interface AxiosRequestConfig {
    options?: ApiCallOptions;
  }
}

export type ApiCallOptions = {
  config?: AxiosRequestConfig;
  withErrorMessageBox?: boolean;
  withAccessToken?: boolean;
};

export const createApiClient = (
  client: AxiosInstance,
  extractErrorMessage?: (e: AxiosError) => string | undefined
) => {
  return async <Response, Request = undefined>(
    method: Uppercase<Method>,
    url: string,
    data: Request | undefined = undefined,
    options?: ApiCallOptions
  ): ApiPromise<Response> => {
    // Apply defaults
    options = { withAccessToken: true, withErrorMessageBox: true, ...options };

    try {
      const response = await client.request({
        ...options.config,
        url,
        method,
        data,
        // Default the Accept header
        headers: { Accept: 'application/json', ...options.config?.headers },
        // Provided for interceptors
        options
      });

      return { errored: false, data: response.data, response };
    } catch (e: unknown) {
      // Don't bother logging authentication failures
      const shouldLog = isAxiosError(e) ? ![401].includes(e.response?.status || 0) : true;

      if (shouldLog) {
        // eslint-disable-next-line no-console
        console.error(e);
        if (import.meta.env.VITE_SENTRY_LOGGING === 'TRUE') {
          Sentry.captureException(e, { level: 'error' });
        }
      }

      // This may have been modified by interceptors, so we need to look at the data in the error first.
      const withErrorMessageBox = isAxiosError(e)
        ? e.config?.options?.withErrorMessageBox
        : options.withErrorMessageBox;

      const message =
        (isAxiosError(e) ? extractErrorMessage?.(e) : undefined) || t('errors.generic');

      if (withErrorMessageBox) {
        void useMessageBox().openErrorMessageBox({ text: message });
      }

      return {
        errored: true,
        data: undefined,
        response: isAxiosError(e) ? e.response : undefined,
        error: { message }
      } as const;
    }
  };
};
