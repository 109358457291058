import type { ColourName, TableColumnSorting } from '@feeditback/fib-components';
import { t } from '@feeditback/fib-components';

import packageJson from '../../package.json';

import type { UserRoleId } from './const';
import { USER_ROLE_TEXT_KEY } from './const';

export const getStoreName = (name: string): string => `${packageJson.name}.${name}`;

export type ExtractSortFields<Request> = Request extends { order?: [infer Field, unknown][] }
  ? Field
  : never;

export const mapSorting = <Field extends string>(
  sorting: TableColumnSorting<Field>[]
): [Field, 'DESC' | 'ASC'][] => {
  return sorting.map(s => [s.name, s.descending ? 'DESC' : 'ASC']);
};

export const getRoleText = (id: UserRoleId): string => {
  return t(`users.roles.${USER_ROLE_TEXT_KEY[id]}.label`);
};

export const getSentimentColour = (value: number): ColourName => {
  return value === 0
    ? 'indicator-neutral'
    : value > 0
      ? 'indicator-positive'
      : 'indicator-negative';
};

type FormatDatetimeOptions = {
  withTime?: boolean;
  locale?: string | string[];
};

type Dateish = string | number | Date;

export const formatDatetime = (value: Dateish, options?: FormatDatetimeOptions): string => {
  const { withTime = true, locale } = options || {};

  const date = new Date(value);
  return withTime ? date.toLocaleString(locale) : date.toLocaleDateString(locale);
};

const SUMMARISE_DATETIME_GRANULARITIES = [
  { amount: 60, name: 'seconds' },
  { amount: 60, name: 'minutes' },
  { amount: 24, name: 'hours' },
  { amount: 7, name: 'days' },
  { amount: 4.34524, name: 'weeks' },
  { amount: 12, name: 'months' },
  { amount: Number.POSITIVE_INFINITY, name: 'years' }
] as const;

type SummariseDatetimeOptions = FormatDatetimeOptions & {
  maxGranularity?: (typeof SUMMARISE_DATETIME_GRANULARITIES)[number]['name'];
  formatOptions?: Intl.RelativeTimeFormatOptions;
};

export const summariseDatetime = (value: Dateish, options?: SummariseDatetimeOptions): string => {
  const {
    maxGranularity = 'days',
    locale,
    formatOptions = { numeric: 'always', style: 'long' }
  } = options || {};

  const date = new Date(value);
  const formatter = new Intl.RelativeTimeFormat(locale, formatOptions);

  let duration = (date.getTime() - new Date().getTime()) / 1000;

  const index = SUMMARISE_DATETIME_GRANULARITIES.findIndex(g => g.name === maxGranularity);
  const granularities = SUMMARISE_DATETIME_GRANULARITIES.slice(
    0,
    index === -1 ? undefined : index + 1
  );

  for (const granularity of granularities) {
    if (Math.abs(duration) < granularity.amount) {
      return formatter.format(Math.round(duration), granularity.name);
    }

    duration /= granularity.amount;
  }

  // Just output the datetime as a fallback if we exceed the max granularity. Default to not
  // representing time as it's likely been summarised, but allow this to be overridden.
  return formatDatetime(date, { withTime: false, ...options });
};
